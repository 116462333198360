import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'
import { ConfigurationToolCategories, ControlKinds, ControlTypes, EnergyDisplayLevels } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { EndpointType, ParseFunctionBlockDefinitionXmlOutputV2 } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { ActionControlAppearance, ActionControlAttributes, ActionsControlAppearance, ActionsControlAttributes, BaseControlAttributes, BooleanDisplayControlAppearance, BooleanDisplayControlAttributes, ControlCommand, ControlViewModelV2, EnumInputControlAppearance, EnumInputControlAttributes, LinksControlAppearance, LinksControlAttributes, NumericDisplayControlAppearance, NumericDisplayControlAttributes, NumericInputControlAppearance, NumericInputControlAttributes, TextDisplayControlAppearance, TextDisplayControlAttributes, TextInputControlAppearance, TextInputControlAttributes, ToggleControlAppearance, ToggleControlAttributes, TogglesControlAppearance, TogglesControlAttributes } from '@ecocoach/shared-components/src/components/deviceControls/v2/models'
import { ControlDefinitionViewModel, ControlDefinitionViewModelV2, DEFAULT_POLLING_INTERVAL_MS, EndpointIsMethodPropertyName, EndpointPropertyName, FunctionDefinitionViewModel } from './models'

export function toControlDefinitionForApi(controlDefinition: ControlDefinitionViewModel, functionDefinition: FunctionDefinitionViewModel,
  propertiesFn: (kind: ControlKinds, type: ControlTypes, isForUi?: boolean) => string[]): any {

  const result = {} as any
  propertiesFn(controlDefinition.kind, controlDefinition.type, false)
    .forEach(property => result[property] = controlDefinition[property])

  endpointPropertiesForKindAndType(controlDefinition, 'api').forEach(endpointProperty => {
    result[endpointProperty] = controlDefinition[endpointProperty]
    const isMethodPropertyName = endpointIsMethodPropertyNameForEndpoint(endpointProperty)
    if (isMethodPropertyName) {
      result[isMethodPropertyName] = controlDefinition[isMethodPropertyName]
      if (result[isMethodPropertyName] === undefined) { // new controld definition, take from fb
        const endpointValue = result[endpointProperty]
        const isMethod = functionDefinition.endpoints.find(ep => ep.internalName === endpointValue)?.endpointType === EndpointType.Method ?? false
        result[isMethodPropertyName] = isMethod
      }
    }
  })
  
  return result
}

export function toControlDefinitions(data: ParseFunctionBlockDefinitionXmlOutputV2): ControlDefinitionViewModel[] {
  const withKind = (arr: any[], kind: ControlKinds): any[] => {
    arr.forEach(obj => obj.kind = kind)
    return arr
  }
  return [
    ...withKind(data.matchingButtonControlDefinitions, ControlKinds.BUTTON_CONTROL),
    ...withKind(data.matchingColorPickerControlDefinitions, ControlKinds.COLOR_PICKER_CONTROL),
    ...withKind(data.matchingMonitoringControlDefinitions, ControlKinds.MONITORING_CONTROL),
    ...withKind(data.matchingParameterControlDefinitions, ControlKinds.PARAMETER_CONTROL),
    ...withKind(data.matchingSwitchControlDefinitions, ControlKinds.SWITCH_CONTROL),
    ...withKind(data.matchingTextControlDefinitions, ControlKinds.TEXT_CONTROL),
  ].map(cd => {
    const controlDefinition = {
      id: cd.id,
      kind: cd.kind,
      type: cd.type,
      obsolete: cd.obsolete ?? false,
      appDisplayLevel: cd.appDisplayLevel,
      configurationToolCategory: cd.configurationToolCategory,
      energyDisplayLevel: cd.energyDisplayLevel ?? EnergyDisplayLevels.NOT_DISPLAYED,
      energyDisplaySummary: cd.energyDisplaySummary ?? false,
      minValue: Number(cd.minValue ?? 0),
      maxValue: Number(cd.maxValue ?? 0),
      interval: Number(cd.interval ?? 0),
      colorGradient: cd.colorGradient ?? '',
      unit: cd.unit ?? '',
      nameResourceId: cd.nameResourceId ?? '',
      descriptionResourceId: cd.descriptionResourceId ?? '',
      dropdownValueList: cd.dropdownValueList ?? [],
      softwareMappingGlobalVariableId: cd.softwareMappingGlobalVariableId ?? '',
      isMeterDataAggregationEnabled: cd.isMeterDataAggregationEnabled ?? false,
      isDynamicMeterControl: cd.isDynamicMeterControl ?? false,
      isMeterActivationControl: cd.isMeterActivationControl ?? false,
      toSubscribe: cd.toSubscribe ?? false,
      pollInterval: cd.pollInterval ?? DEFAULT_POLLING_INTERVAL_MS,
      isExisting: true,
    } as ControlDefinitionViewModel
    Object.values(EndpointPropertyName).forEach(endpointProperty => {
      controlDefinition[endpointProperty] = cd[endpointProperty] ?? ''
    })
    Object.values(EndpointIsMethodPropertyName).forEach(isMethodEndpointProperty => {
      controlDefinition[isMethodEndpointProperty] = cd[isMethodEndpointProperty] ?? false
    })
    return controlDefinition
  })
}

export function isWritableEndpointType(endpoint: EndpointPropertyName): boolean {
  switch (endpoint) {
    case EndpointPropertyName.ReadEndpoint:
    case EndpointPropertyName.RgbArrayReadEndpoint:
    case EndpointPropertyName.SwitchOutputEndpoint:
    case EndpointPropertyName.SwitchOutputEndpoint2:
      return false
    default: return true
  }
}

export function isReadableEndpointType(endpoint: EndpointPropertyName): boolean {
  switch (endpoint) {
    case EndpointPropertyName.ReadEndpoint:
    case EndpointPropertyName.RgbArrayReadEndpoint:
    case EndpointPropertyName.SwitchOutputEndpoint:
    case EndpointPropertyName.SwitchOutputEndpoint2:
      return true
    default: return false
  }
}

export function isInvokableEndpointType(endpoint: EndpointPropertyName): boolean {
  switch (endpoint) {
    case EndpointPropertyName.ActivateEndpoint:
    case EndpointPropertyName.Button1Endpoint:
    case EndpointPropertyName.Button2Endpoint:
    case EndpointPropertyName.SwitchOffEndpoint:
    case EndpointPropertyName.SwitchOffEndpoint2:
    case EndpointPropertyName.SwitchOnEndpoint:
    case EndpointPropertyName.SwitchOnEndpoint2:
      return true
    default: return false
  }
}

export function endpointIsMethodPropertyNameForEndpoint(endpoint: EndpointPropertyName): EndpointIsMethodPropertyName | undefined {
  switch (endpoint) {
    case EndpointPropertyName.ActivateEndpoint: return EndpointIsMethodPropertyName.ActivateEndpointIsMethod
    case EndpointPropertyName.Button1Endpoint: return EndpointIsMethodPropertyName.Button1IsMethod
    case EndpointPropertyName.Button2Endpoint: return EndpointIsMethodPropertyName.Button2IsMethod
    case EndpointPropertyName.SwitchOffEndpoint: return EndpointIsMethodPropertyName.OffEndpointIsMethod
    case EndpointPropertyName.SwitchOffEndpoint2: return EndpointIsMethodPropertyName.OffEndpoint2IsMethod
    case EndpointPropertyName.SwitchOnEndpoint: return EndpointIsMethodPropertyName.OnEndpointIsMethod
    case EndpointPropertyName.SwitchOnEndpoint2: return EndpointIsMethodPropertyName.OnEndpoint2IsMethod
    default: return undefined
  }
}

export function endpointPropertiesForKindAndType(params: { kind: ControlKinds, type: ControlTypes }, mode: 'default' | 'validation' | 'api' = 'default'): EndpointPropertyName[] {
  switch (params.kind) {
    case ControlKinds.BUTTON_CONTROL: return params.type === ControlTypes.UIButton
      ? [EndpointPropertyName.Button1Endpoint]
      : [EndpointPropertyName.Button1Endpoint, EndpointPropertyName.Button2Endpoint]
    case ControlKinds.COLOR_PICKER_CONTROL: return [
      EndpointPropertyName.ActivateEndpoint,
      EndpointPropertyName.RgbArrayReadEndpoint,
      EndpointPropertyName.RgbArrayWriteEndpoint,
      EndpointPropertyName.AdoptColorEndpoint,
    ]
    case ControlKinds.MONITORING_CONTROL: return [
      EndpointPropertyName.ReadEndpoint,
    ]
    case ControlKinds.PARAMETER_CONTROL: {
      return mode === 'validation'
        ? [EndpointPropertyName.ReadEndpoint, EndpointPropertyName.WriteEndpoint]
        : [EndpointPropertyName.ReadEndpoint, EndpointPropertyName.WriteEndpoint, EndpointPropertyName.ActivateEndpoint]
    }
    case ControlKinds.SWITCH_CONTROL: return params.type === ControlTypes.UIUpDownSwitch
      ? [EndpointPropertyName.SwitchOnEndpoint, EndpointPropertyName.SwitchOffEndpoint, EndpointPropertyName.SwitchOutputEndpoint, 
        EndpointPropertyName.SwitchOnEndpoint2, EndpointPropertyName.SwitchOffEndpoint2, EndpointPropertyName.SwitchOutputEndpoint2]
      : [EndpointPropertyName.SwitchOnEndpoint, EndpointPropertyName.SwitchOffEndpoint, EndpointPropertyName.SwitchOutputEndpoint]
    case ControlKinds.TEXT_CONTROL: return [
      EndpointPropertyName.ReadEndpoint,
      EndpointPropertyName.WriteEndpoint,
    ]
    default: return[]
  }
}

export function releaseNotesResourceId(internalName: string, version: string) {
  return `FunctionDefinitions_${internalName}_${version}_ReleaseNotes`
}

export function isConfigurationToolCategory(controlDefinition: ControlDefinitionViewModel | ControlDefinitionViewModelV2, 
  category: ConfigurationToolCategories) {
  return (controlDefinition as ControlDefinitionViewModel).configurationToolCategory === category
    || (controlDefinition as ControlDefinitionViewModelV2).attributes?.configurationToolCategory === category
}

export function mapToControlViewModelV2(controlDefinition: ControlDefinitionViewModel): ControlViewModelV2 {
  const control = {
    id: controlDefinition.id,
    type: '' as ControlTypeV2,
    attributes: {
      label: controlDefinition.nameResourceId,
      tooltip: controlDefinition.descriptionResourceId,
      appDisplayLevel: controlDefinition.appDisplayLevel,
      configurationToolCategory: controlDefinition.configurationToolCategory,
      ecocoachOnly: false,
    } as BaseControlAttributes,
    state: {},
    pendingState: {},
  } as ControlViewModelV2
  
  switch (controlDefinition.kind) {
    case ControlKinds.SWITCH_CONTROL:
      mapSwitchControl(controlDefinition, control)
      break
    case ControlKinds.PARAMETER_CONTROL:
      mapParameterControl(controlDefinition, control)
      break
    case ControlKinds.MONITORING_CONTROL:
      mapMonitoringControl(controlDefinition, control)
      break
    case ControlKinds.BUTTON_CONTROL:
      mapButtonControl(controlDefinition, control)
      break
    case ControlKinds.COLOR_PICKER_CONTROL:
      mapColorPickerControl(controlDefinition, control)
      break
    case ControlKinds.TEXT_CONTROL:
      mapTextControl(controlDefinition, control)
      break
  }
  return control
}

function mapSwitchControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UISwitch:
    case ControlTypes.UISwitchButton:
      control.type = ControlTypeV2.Toggle
      control.attributes = {
        ...control.attributes,
        appearance: ToggleControlAppearance.Switch,
        toggle: {
          command: 'command',
          state: 'state',
        },
      } as ToggleControlAttributes
      break
    case ControlTypes.UILock:
      control.type = ControlTypeV2.Toggle
      control.attributes = {
        ...control.attributes,
        appearance: ToggleControlAppearance.Lock,
        toggle: {
          command: 'command',
          state: 'state',
        },
      } as ToggleControlAttributes
      break
    case ControlTypes.UICheckbox:
      control.type = ControlTypeV2.Toggle
      control.attributes = {
        ...control.attributes,
        appearance: ToggleControlAppearance.Checkbox,
        toggle: {
          command: 'command',
          state: 'state',
        },
      } as ToggleControlAttributes
      break
    case ControlTypes.UIUpDownSwitch:
      control.type = ControlTypeV2.Toggles
      control.attributes = {
        ...control.attributes,
        appearance: TogglesControlAppearance.IconButtons,
        toggles: [
          {
            command: 'command1',
            state: 'state1',
            icon: 'keyboard_arrow_up',
          },
          {
            command: 'command2',
            state: 'state2',
            icon: 'keyboard_arrow_down',
          },
        ],
      } as TogglesControlAttributes
      break
  }
}

function mapParameterControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UISlider:
      control.type = ControlTypeV2.NumericInput
      control.attributes = {
        ...control.attributes,
        appearance: NumericInputControlAppearance.Slider,
        minValue: controlDefinition.minValue,
        maxValue: controlDefinition.maxValue,
        interval: controlDefinition.interval,
        minValueLabel: controlDefinition.nameResourceId.replace('_Name', '_MinimumValueLabel'),
        maxValueLabel: controlDefinition.nameResourceId.replace('_Name', '_MaximumValueLabel'),
        unit: controlDefinition.unit,
        gradient: controlDefinition.colorGradient,
        command: 'command',
        state: 'state',
      } as NumericInputControlAttributes
      break
    case ControlTypes.UIValuePicker:
      control.type = ControlTypeV2.NumericInput
      control.attributes = {
        ...control.attributes,
        appearance: NumericInputControlAppearance.Input,
        minValue: controlDefinition.minValue,
        maxValue: controlDefinition.maxValue,
        interval: controlDefinition.interval,
        unit: controlDefinition.unit,
        command: 'command',
        state: 'state',
      } as NumericInputControlAttributes
      break
    case ControlTypes.UISoftwareMappingTarget:
      control.type = ControlTypeV2.Links
      control.attributes = {
        ...control.attributes,
        appearance: LinksControlAppearance.Dropdown,
        options: [{ id: '', label: '-' }],
        maxNumSelections: 1,
        command: 'command',
        state: 'state',
      } as LinksControlAttributes
      break
    case ControlTypes.UIDropdownValueList:
      control.type = ControlTypeV2.EnumInput
      control.attributes = {
        ...control.attributes,
        appearance: EnumInputControlAppearance.Dropdown,
        options: controlDefinition.dropdownValueList?.map(_ => ({ id: _.value, label: _.resourceId })),
        command: 'command',
        state: 'state',
      } as EnumInputControlAttributes
      break
  }
}

function mapMonitoringControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UICircle:
      control.type = ControlTypeV2.NumericDisplay
      control.attributes = {
        ...control.attributes,
        appearance: NumericDisplayControlAppearance.PercentageCircle,
        unit: controlDefinition.unit,
        decimals: 1,
        invalidValue: 1e300,
        invalidDisplayValue: 'n/a',
        state: 'state',
      } as NumericDisplayControlAttributes
      break
    case ControlTypes.UIValue:
      control.type = ControlTypeV2.NumericDisplay
      control.attributes = {
        ...control.attributes,
        appearance: NumericDisplayControlAppearance.Label,
        unit: controlDefinition.unit,
        decimals: 1,
        invalidValue: 1e300,
        invalidDisplayValue: 'n/a',
        state: 'state',
      } as NumericDisplayControlAttributes
      break
    case ControlTypes.UIMonitoringLock:
      control.type = ControlTypeV2.BooleanDisplay
      control.attributes = {
        ...control.attributes,
        appearance: BooleanDisplayControlAppearance.Icons,
        iconTrue: 'lock_open',
        iconFalse: 'lock',
      } as BooleanDisplayControlAttributes
      control.attributes.appearance = BooleanDisplayControlAppearance.Icons
      break
    case ControlTypes.UIAlarm:
      control.type = ControlTypeV2.BooleanDisplay
      control.attributes = {
        ...control.attributes,
        appearance: BooleanDisplayControlAppearance.Icons,
        iconTrue: 'warning',
        iconFalse: 'check_circle',
      } as BooleanDisplayControlAttributes
      break
    case ControlTypes.UIOnOff:
      control.type = ControlTypeV2.BooleanDisplay
      control.attributes = {
        ...control.attributes,
        appearance: BooleanDisplayControlAppearance.Icons,
        iconTrue: 'power',
        iconFalse: 'power_off',
      } as BooleanDisplayControlAttributes
      break      
    case ControlTypes.UIErrorMessage:
      control.type = ControlTypeV2.TextDisplay
      control.attributes = {
        appearance: TextDisplayControlAppearance.Label,
        ...control.attributes,
      } as TextDisplayControlAttributes
      break
  }
}

function mapButtonControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UIButton:
      control.type = ControlTypeV2.Action
      control.attributes = {
        ...control.attributes,
        appearance: ActionControlAppearance.TextButton,
        action: {
          command: 'command',
          text: controlDefinition.nameResourceId,
        },
        confirmationText: '',
      } as ActionControlAttributes
      break
    case ControlTypes.UIUpDownButton:
      control.type = ControlTypeV2.Actions
      control.attributes = {
        ...control.attributes,
        appearance: ActionsControlAppearance.IconButton,
        actions: [
          {
            command: 'command1',
            icon: 'keyboard_arrow_up',
          },
          {
            command: 'command2',
            icon: 'keyboard_arrow_down',
          },
        ],
      } as ActionsControlAttributes
      break
    case ControlTypes.UIPlusMinusButton:
      control.type = ControlTypeV2.Actions
      control.attributes = {
        ...control.attributes,
        appearance: ActionsControlAppearance.IconButton,
        actions: [
          {
            command: 'command1',
            icon: 'add',
          },
          {
            command: 'command2',
            icon: 'remove',
          },
        ],
      } as ActionsControlAttributes
  }
}

function mapColorPickerControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UIHSL:
      control.type = ControlTypeV2.NumericInput
      control.attributes = {
        ...control.attributes,
        appearance: NumericInputControlAppearance.HueColorSlider,
        minValue: 0,
        maxValue: 359,
        interval: 1,
        command: 'command',
        state: 'state',
      } as NumericInputControlAttributes
      break
  }
}

function mapTextControl(controlDefinition: ControlDefinitionViewModel, control: ControlViewModelV2) {
  switch (controlDefinition.type) {
    case ControlTypes.UIStringPicker:
      control.type = ControlTypeV2.TextInput
      control.attributes = {
        ...control.attributes,
        appearance: TextInputControlAppearance.Input,
        command: 'command',
        state: 'state',
      } as TextInputControlAttributes      
      control.attributes.appearance = TextInputControlAppearance.Input
      control.attributes.command = 'command'
      control.attributes.state = 'state'
      break
  }
}

export function mapToClassicDefaultValue(controlDefinition: ControlDefinitionViewModel, command: ControlCommand): any {
  switch (controlDefinition.kind) {
    case ControlKinds.SWITCH_CONTROL:
      return {
        switchSelection: 'switch1',
        toState: command.params ? 'on' : 'off',
      }
    case ControlKinds.PARAMETER_CONTROL:
      return {
        value: command.params,
      }
    case ControlKinds.COLOR_PICKER_CONTROL:
      return {
        hue: command.params,
        lightness: 50,
        saturation: 100,
      }
    case ControlKinds.TEXT_CONTROL:
      return {
        value: command.params,
      }
  }
}

export function mapToState(controlDefinition: ControlDefinitionViewModel, command: ControlCommand): any {
  switch (controlDefinition.kind) {
    case ControlKinds.SWITCH_CONTROL:
      return {
        switchSelection: 'switch1',
        toState: command.params ? 'on' : 'off',
      }
    case ControlKinds.PARAMETER_CONTROL:
      return {
        value: command.params,
      }
    case ControlKinds.COLOR_PICKER_CONTROL:
      return {
        hue: command.params,
        lightness: 50,
        saturation: 100,
      }
    case ControlKinds.TEXT_CONTROL:
      return {
        value: command.params,
      }
  }
}