<template>
  <table class="table">
    <tr @click.stop="toggleAlarmDefinitionsList" class="headerRow">
      <th valign="middle" class="iconCol">
        <svg-icon :icon="plcPermissions.icon" width="25" height="25"/>
      </th>
      <th valign="middle" class="descriptionCol"> {{ plcPermissions.name }} </th>
      <th valign="middle" class="deviceNameCol"> {{ 'authorizationLevel.deviceName' | translate  }} </th>
      <th v-if="alarmDefinitions.length > 0"
        valign="middle"
        class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.customNotification' | translate }}
          <div @click.stop="toggleAlarmCustomNotificationPermissionForPlc">
            <svg-icon :icon="checkboxIcon(plcPermissions.hasCustomNotification)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th v-if="alarmDefinitions.length > 0"
        valign="middle"
        class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.customNotificationEmail' | translate }}
          <div @click.stop="toggleAlarmCustomNotificationEmailPermissionForPlc">
            <svg-icon :icon="checkboxIcon(plcPermissions.hasCustomNotificationEmail)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th v-if="alarmDefinitions.length > 0" valign="middle" class="collapse">
        <v-icon @click.stop="toggleAlarmDefinitionsList">
          {{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
        </v-icon>
      </th>
      <th v-else valign="middle">
        <div class="infoCol">
          {{ 'authorization.plc.no.alarms' | translate }}
        </div>
      </th>
    </tr>
    <tr v-for="alarmPermission in alarmPermissionsOfPlc" :key="alarmPermission.id" class="alarmRow">
      <td valign="middle" class="iconCol">
        <svg-icon :icon="alarmPermission.icon" width="25" height="25"/>
      </td>
      <td valign="middle" class="descriptionCol"> {{ alarmPermission.name }} </td>
      <td valign="middle" class="deviceNameCol"> {{ alarmPermission.deviceName }} </td>
      <td valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.customNotification' | translate }}
          <div @click="toggleAlarmCustomNotificationPermission(alarmPermission)">
            <svg-icon :icon="checkboxIcon(alarmPermission.hasCustomNotification)" width="25" height="25"/>
          </div>
        </div>
      </td>
      <td valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.customNotificationEmail' | translate }}
          <div @click="toggleAlarmCustomNotificationEmailPermission(alarmPermission)">
            <svg-icon :icon="checkboxIcon(alarmPermission.hasCustomNotificationEmail)" width="25" height="25"/>
          </div>
        </div>
      </td>
      <td valign="middle" class="collapse"></td>
    </tr>
  </table>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AlarmDefinitionAuthorizationsModel, Permission, PlcAuthorizationsModel } from '../../../../../eco-domain-store-modules/src/authorization/models'

import { PermissionsViewModel } from '../../../store/modules/authorizationUi/models'

import SvgIcon from '../../ui/SvgIcon.vue'

const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    SvgIcon,
  },
})
export default class AlarmDefinitionPermissions extends Vue {
  @Prop() public plc: PlcAuthorizationsModel
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Getter public alarmDefinitionsOfPlc: (plcId: string) => AlarmDefinitionAuthorizationsModel[]
  @AuthorizationUi.Getter public alarmDefinitionPermissions: (alarmDefinitionId: string) => PermissionsViewModel
  @AuthorizationUi.Getter public alarmDefinitionPermissionsForPlc: (plcId: string) => PermissionsViewModel
  @AuthorizationUi.Action public grantAlarmDefinitionPermission: (payload: { alarmDefinitionId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeAlarmDefinitionPermission: (payload: { alarmDefinitionId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public grantAlarmDefinitionPermissionForPlc: (payload: { plcId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeAlarmDefinitionPermissionForPlc: (payload: { plcId: string, permission: Permission }) => Promise<void>

  public collapsed = true

  public get alarmDefinitions(): AlarmDefinitionAuthorizationsModel[] {
    return this.alarmDefinitionsOfPlc(this.plc.id)
  }

  public get plcPermissions(): PermissionsViewModel {
    return this.alarmDefinitionPermissionsForPlc(this.plc.id)
  }

  public get alarmPermissionsOfPlc(): PermissionsViewModel[] {
    return this.collapsed ? [] : this.alarmDefinitions.map(d => this.alarmDefinitionPermissions(d.id))
  }

  public checkboxIcon(value?: boolean | null) {
    if (value === null) {
      return 'eco.checkbox-partial.white'
    }
    return value ? 'eco.checkbox-all.white' : 'eco.checkbox-none.white'
  }

  public toggleAlarmCustomNotificationPermissionForPlc() {
    if (this.plcPermissions.hasCustomNotification) {
      this.revokeAlarmDefinitionPermissionForPlc({ plcId: this.plcPermissions.id, permission: Permission.CUSTOM_NOTIFICATION })
    } else {
      this.grantAlarmDefinitionPermissionForPlc({ plcId: this.plcPermissions.id, permission: Permission.CUSTOM_NOTIFICATION })
    }
  }

  public toggleAlarmCustomNotificationEmailPermissionForPlc() {
    if (this.plcPermissions.hasCustomNotificationEmail) {
      this.revokeAlarmDefinitionPermissionForPlc({ plcId: this.plcPermissions.id, permission: Permission.CUSTOM_NOTIFICATION_EMAIL })
    } else {
      this.grantAlarmDefinitionPermissionForPlc({ plcId: this.plcPermissions.id, permission: Permission.CUSTOM_NOTIFICATION_EMAIL })
    }
  }

  public toggleAlarmCustomNotificationPermission(alarmPermission: PermissionsViewModel) {
    if (alarmPermission.hasCustomNotification) {
      this.revokeAlarmDefinitionPermission({ alarmDefinitionId: alarmPermission.id, permission: Permission.CUSTOM_NOTIFICATION })
    } else {
      this.grantAlarmDefinitionPermission({ alarmDefinitionId: alarmPermission.id, permission: Permission.CUSTOM_NOTIFICATION })
    }
  }

  public toggleAlarmCustomNotificationEmailPermission(alarmPermission: PermissionsViewModel) {
    if (alarmPermission.hasCustomNotificationEmail) {
      this.revokeAlarmDefinitionPermission({ alarmDefinitionId: alarmPermission.id, permission: Permission.CUSTOM_NOTIFICATION_EMAIL })
    } else {
      this.grantAlarmDefinitionPermission({ alarmDefinitionId: alarmPermission.id, permission: Permission.CUSTOM_NOTIFICATION_EMAIL })
    }
  }

  public toggleAlarmDefinitionsList() {
    if(this.alarmDefinitions.length > 0) {
      this.collapsed = !this.collapsed
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  table-layout: fixed;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  th {
    &.iconCol {
      width: 36px;
      justify-content: center;
      text-align: left;
    }
    &.descriptionCol {
      justify-items: center;
      text-align: left;
      width: 20%;
    }
    &.authCol {
      width: 33%;
    }
    &.collapse {
      width: 7%;
      cursor: pointer;
      .material-icons {
        color: white;
      }
    }
  }
  td, th {
    padding: 10px 10px;
    font-weight: normal;
    &.iconCol {
      justify-content: center;
      text-align: left;
      overflow: hidden;
    }
    &.descriptionCol {
      justify-items: center;
      text-align: left;
    }
    &.deviceNameCol {
      justify-items: center;
      text-align: left;
      width: 20%;
    }
    &.collapse {
      .material-icons {
        overflow: hidden;
        max-width: 24px;
        color: white;
      }
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #20476e, #20476e); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #20476e), color-stop(0.6, #20476e));
  }
  .alarmRow {
    background: linear-gradient(to bottom, #1a3856, #1a3856); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #1a3856));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -5px;
    padding-left: 10px;
    > div {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>
