import { AuditActionControlCommandExecution, AuditActionType, AuditLogEntryModel } from '@ecocoach/domain-store-modules/src/auditLog/models'
import { replaceStringTemplate } from '@ecocoach/domain-store-modules/src/utils'
import moment from 'moment'
import { AuditLogEntryViewModel } from './models'

export function toViewModel(entry: AuditLogEntryModel, resolveStringResource: (resourceId: string) => string) {
  return {
    ...entry,
    dateTimeFormatted: formatTimeStamp(entry.timeStampInUtc),
    actionTypeText: actionTypeText(entry.actionType, resolveStringResource),
    actionText: actionText(entry.userAction, resolveStringResource),
    actionDetails: actionDetails(entry.userAction, resolveStringResource),
  } as AuditLogEntryViewModel
}

export function formatTimeStamp(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('DD.MM.YYYY - HH:mm:ss')
}

export function actionTypeText(actionType: AuditActionType, resolveStringResource: (resourceId: string) => string) {
  switch (actionType) {
    case AuditActionType.ControlCommandExecution: return resolveStringResource('audit.type.controlCommandExecution')
    default: return ''
  }
}

function actionText(userAction: AuditActionControlCommandExecution, resolveStringResource: (resourceId: string) => string) {
  return userAction.resourceId ? resolveStringResource(userAction.resourceId) : 'n/a'
}

function actionDetails(userAction: AuditActionControlCommandExecution, resolveStringResource: (resourceId: string) => string) {
  const templateString = resolveStringResource('audit.type.controlCommandExecution.details')
  const data = {
    ...userAction,
    controlNameResourceId: resolveStringResource(userAction.controlNameResourceId),
  }
  return replaceStringTemplate(templateString, data)
}