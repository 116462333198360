<template>
  <v-data-table :headers="headers" :items="alarmDefinitions" :pagination.sync="pagination" item-key="id"
    class="alarm-definitions-table"
    :custom-filter="filteredAlarmDefinitions"
    :search="''"
  >
    <template v-slot:headers="props">
      <tr class="table-header-row">
        <th v-for="header in props.headers" :key="header.value" 
          :class="[
            'column', 
            header.align === 'center' ? 'text-xs-center' : 'text-xs-left',
            header.sortable !== false ? 'sortable' : '',
            pagination.descending ? 'desc' : 'asc', 
            header.value === pagination.sortBy ? 'active' : ''
          ]"
          :style="{ 
            display: header.hidden === true ? 'none' : 'table-cell',
            width: header.width,
          }"
        >
          <div class="table-header" @click="changeSort(header)">
            {{ header.text }}
            <v-icon v-if="header.sortable !== false" small>arrow_upward</v-icon>
          </div>
          <table-filter-text-search v-if="header.type === 'textsearch'"
            :value="header.search"
            :placeholder="header.placeholder"
            @changed="header.changed"
          />
          <table-filter-multi-select v-if="header.type === 'multiselect'"
            :options="header.options"
            :values="header.selectedValues"
            :placeholder="header.placeholder"
            @selected="header.selected"
            @cleared="header.cleared"
          />
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <tr :key="props.item.id" :class="{ 'selected': props.item.id === selectedAlarmDefinitionId }" @click="setSelectedAlarmDefinitionId(props.item.id)">
        <td :title="levelTooltip(props.item)">
          <icon-with-badge class="alarm-level-icon" material-icon="notifications" :badge="levelBadge(props.item)" :badge-color="levelBadgeColor(props.item)" />
        </td>
        <td>{{ props.item.displayName }}</td>
        <td>{{ props.item.controlName }}</td>
        <td>{{ props.item.option.deviceName }}</td>
        <td>{{ props.item.option.roomName }}</td>
        <td>{{ props.item.alarmConditionText }}</td>
        <td>{{ props.item.displayMessage }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import IconWithBadge from '../../../../ui/IconWithBadge.vue'
import TableFilterMultiSelect from '../../../../ui/TableFilterMultiSelect.vue'
import TableFilterTextSearch from '../../../../ui/TableFilterTextSearch.vue'

import { AlarmDefinitionTableFilterSettings, AlarmDefinitionViewModel } from '../../../../../store/modules/manageAlarmsUi/models'

import { AlarmLevel } from '../../../../../../../eco-domain-store-modules/src/common'

import { DropdownOption } from '../../../../../../../shared-components/src'

const Resource = namespace('resource')
const ManageAlarmsUi = namespace('manageAlarmsUi')

@Component({
  components: {
    IconWithBadge,
    TableFilterMultiSelect,
    TableFilterTextSearch,
  },
})
export default class AlarmDefinitionsTable extends Vue {
  @ManageAlarmsUi.Getter public isInteracted: boolean
  @ManageAlarmsUi.Getter public selectedAlarmDefinitionId: string
  @ManageAlarmsUi.Getter public levelOptions: DropdownOption[]
  @ManageAlarmsUi.Getter public filterSettings: AlarmDefinitionTableFilterSettings
  @ManageAlarmsUi.Getter public alarmDefinitions: AlarmDefinitionViewModel[]
  @ManageAlarmsUi.Getter public filteredAlarmDefinitions: (alarmDefinitions: AlarmDefinitionViewModel[]) => AlarmDefinitionViewModel[]
  @ManageAlarmsUi.Mutation public setSelectedAlarmDefinitionId: (id: string) => void
  @ManageAlarmsUi.Action public updateFilterSettings: (payload: { property: string, value: any }) => Promise<void>

  @Resource.Getter public dictionary

  public pagination = {
    sortBy: 'name',
    descending: false,
    rowsPerPage: -1,
  }

  public changeSort(column) {
    if (column.sortable === false) {
      return
    }
    if (this.pagination.sortBy === column.value) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column.value
      this.pagination.descending = false
    }
  }

  public get headers() {
    return [
      { 
        text: this.dictionary('alarmdefinition.level'),
        value: 'level',
        type: 'multiselect',
        options: this.levelOptions,
        selectedValues: this.filterSettings.levels,
        placeholder: this.dictionary('common.filter'),
        selected: (values: boolean[]) => this.updateFilterSettings({ 
          property: 'levels',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'levels',
          value: [],
        }),
        width: '10%',
      },
      {
        text: this.dictionary('alarmdefinition.name'),
        value: 'displayName',
        type: 'textsearch',
        search: this.filterSettings.name,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'name',
          value: searchString,
        }),
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.controlName'),
        value: 'controlName',
        type: 'textsearch',
        search: this.filterSettings.controlName,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'controlName',
          value: searchString,
        }),
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.deviceName'),
        value: 'option.deviceName',
        type: 'textsearch',
        search: this.filterSettings.deviceName,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'deviceName',
          value: searchString,
        }),
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.roomName'),
        value: 'option.roomName',
        type: 'textsearch',
        search: this.filterSettings.roomName,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'roomName',
          value: searchString,
        }),
        width: '20%',
      },
      { 
        text: this.dictionary('alarmdefinition.condition'),
        value: 'id',
        align: 'center',
        sortable: false,
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.message'),
        value: 'displayMessage',
        type: 'textsearch',
        search: this.filterSettings.message,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'message',
          value: searchString,
        }),
        width: '20%',
      },
    ]
  }

  public levelTooltip(model: AlarmDefinitionViewModel) {
    return this.levelOptions.find(_ => _.id === model.level)?.label ?? model.level
  }

  public levelBadgeColor(model: AlarmDefinitionViewModel) {
    switch (model.level) {
      case AlarmLevel.Critical: return 'red'
      case AlarmLevel.Information: return 'blue'
      default: return 'red'
    }
  }

  public levelBadge(model: AlarmDefinitionViewModel) {
    switch (model.level) {
      case AlarmLevel.Critical: return '!'
      case AlarmLevel.Information: return 'i'
      default: return '!'
    }
  }
}
</script>
<style lang="scss" scoped>
th, td {
  padding: 0px 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
th:first-child, td:first-child {
  padding-left: 0px !important;
}
.table-header-row {
  vertical-align: top;
}
.table-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
tr.selected {
  background: #c2043e !important;
}
.alarm-level-icon {
  margin-left: 10px;
}
</style>
<style lang="css">
.alarm-definitions-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
  min-width: 1000px;
}
.alarm-definitions-table .v-datatable__actions {
  display: none;
}
.alarm-definitions-table .autocomplete-input input{
  cursor: pointer !important;
}
</style>