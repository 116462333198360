<template>
  <modal-dialog :show="show"
    :header="`${configuration ? 'logtool.editendpointrecording' : 'logtool.createendpointrecording'}` | translate"
    :button1-text="`${configuration ? 'common.button.save' : 'common.button.create'}` | translate"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted || !canCreate"
    :button2-disabled="isInteracted"
    @button1Pressed="onConfirm"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-tabs @change="onTabChange">
        <v-tab>{{'logtool.device' | translate}}</v-tab>
        <v-tab>{{'logtool.control' | translate}}</v-tab>
        <v-tab>{{'logtool.endpoint' | translate}}</v-tab>
        <v-tab-item>
          <form-field :text="'logtool.device' | translate">
            <autocomplete-select 
              :options="deviceOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedDeviceId"
              :disabled="isInteracted"
              @selected="selectDevice($event)"
            />
          </form-field>
          <form-field :text="'logtool.endpoint' | translate">
            <autocomplete-select 
              :options="endpointOptions"
              option-value="id"
              option-label="label"
              :selected-value="endpoint"
              :disabled="isInteracted || !selectedDeviceId"
              @selected="endpoint = $event"
            />
          </form-field>
          <form-field :text="'logtool.endpointpostfix' | translate">
            <text-input
              :value="endpointPostfix"
              :disabled="!endpoint"
              @input="endpointPostfix = $event"
            />
          </form-field>
        </v-tab-item>
        <v-tab-item>
          <form-field :text="'logtool.device' | translate">
            <autocomplete-select 
              :options="deviceOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedDeviceId"
              :disabled="isInteracted"
              @selected="selectDevice($event)"
            />
          </form-field>
          <form-field :text="'logtool.control' | translate">
            <autocomplete-select
              :options="controlOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedControlId"
              :disabled="isInteracted || !selectedDeviceId"
              @selected="selectedControlId = $event"
            />
          </form-field>
        </v-tab-item>
        <v-tab-item>
          <form-field :text="'logtool.endpoint' | translate">
            <text-input
              :value="qualifiedEndpointPath"
              @input="qualifiedEndpointPath = $event"
            />
          </form-field>
        </v-tab-item>
      </v-tabs>
      <v-flex>
        <form-field :text="'logtool.starttime' | translate">
          <div class="date-time-select">
            <date-select
              :value="startDate"
              :language="selectedLanguage"
              :min="minDate"
              @changed="onStartDateChanged"
            />
            <time-select
              :value="startTime"
              @changed="onStartTimeChanged"
            />
          </div>
        </form-field>
        <form-field :text="'logtool.endtime' | translate">
          <div class="date-time-select">
            <date-select 
              :value="endDate"
              :language="selectedLanguage"
              @changed="onEndDateChanged"
            />
            <time-select 
              :value="endTime" 
              @changed="onEndTimeChanged"
            />
          </div>
        </form-field>
        <form-field :text="dictionary('logtool.interval') + ' (sec)'">
          <text-input type="number" pattern="\d*"
            :value="interval"
            :disabled="isInteracted"
            @input="interval = Number($event)"
          />
        </form-field>
      </v-flex>
    </v-layout>
    <confirm-dialog
      :show="showSaveConfirmationDialog"
      :header="'common.button.save' | translate"
      :text="'logtool.editwarning' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onSave"
      @cancelled="showSaveConfirmationDialog = false"
    />  
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '@ecocoach/shared-components/src'

import { getLocalDateFromUtcDateTime, getLocalTimeFromUtcDateTime, parseDeviceIdFromEndpoint, todayLocalDate, updateUtcDateTimeWithLocalDate, updateUtcDateTimeWithLocalTime } from '../../../../../store/modules/logToolUi/helpers'
import { EndpointRecordingConfigurationModel, EndpointRecordingConfigurationOptions } from '../../../../../store/modules/logToolUi/models'

import moment from 'moment'

const oneThousand = 1000

const LogToolUi = namespace('logToolUi')
const Resource = namespace('resource')
const App = namespace('app')

@Component({
  components: {
  },
})
export default class EditEndpointRecordingDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public configuration: EndpointRecordingConfigurationModel
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public deviceOptions: DropdownOption[]
  @LogToolUi.Getter public controlOptions: DropdownOption[]
  @LogToolUi.Getter public endpointOptions: DropdownOption[]
  @LogToolUi.Getter public selectedDeviceId: string
  @LogToolUi.Action public selectDevice: (id: string) => Promise<void>
  @LogToolUi.Action public createPlcEndpointRecordingConfiguration: 
    (payload: { qualifiedEndpointPath: string, options: EndpointRecordingConfigurationOptions}) => Promise<void>
  @LogToolUi.Action public createDeviceEndpointRecordingConfiguration: 
    (payload: { endpointPath: string, options: EndpointRecordingConfigurationOptions}) => Promise<void>
  @LogToolUi.Action public createControlEndpointsRecordingConfiguration: 
    (payload: { controlId: string, options: EndpointRecordingConfigurationOptions}) => Promise<void>
  @LogToolUi.Action public deleteEndpointRecordingConfiguration: (id: string) => Promise<void>
  @App.Getter public selectedLanguage: string
  @Resource.Getter public dictionary

  public tab = 0
  public selectedControlId = ''
  public endpoint = ''
  public endpointPostfix = ''
  public startDateTime = ''
  public endDateTime = ''
  public interval = 1
  public qualifiedEndpointPath = ''

  public showSaveConfirmationDialog = false

  @Watch('show')
  public async onConfigurationChanged() {
    if (this.configuration) { // edit
      const deviceId = parseDeviceIdFromEndpoint(this.configuration.qualifiedEndpointPath)
      if (deviceId) {
        await this.selectDevice(deviceId)
      }
      this.endpoint = this.endpointOptions.find(o => this.configuration.qualifiedEndpointPath.includes(o.id as string))?.id as string ?? ''
      this.endpointPostfix = this.configuration.qualifiedEndpointPath.split(this.endpoint).reverse()[0] ?? ''
      this.startDateTime = this.configuration.startDateTimeUtc
      this.endDateTime = this.configuration.endDateTimeUtc
      this.interval = this.configuration.intervalInMilliseconds / oneThousand
      this.qualifiedEndpointPath = this.configuration.qualifiedEndpointPath
    } else { // create
      this.startDateTime = moment.utc().startOf('minute').toISOString()
      this.endDateTime = moment.utc().startOf('minute').add(1, 'day').toISOString()
    }
  }

  public get startDate() {
    return getLocalDateFromUtcDateTime(this.startDateTime)
  }

  public get startTime() {
    return getLocalTimeFromUtcDateTime(this.startDateTime)
  }

  public onStartDateChanged(newLocalDate: string) {
    this.startDateTime = updateUtcDateTimeWithLocalDate(this.startDateTime, newLocalDate)
  }

  public onStartTimeChanged(newLocalTime: string) {
    this.startDateTime = updateUtcDateTimeWithLocalTime(this.startDateTime, newLocalTime)
  }

  public get endDate() {
    return getLocalDateFromUtcDateTime(this.endDateTime)
  }

  public get endTime() {
    return getLocalTimeFromUtcDateTime(this.endDateTime)
  }

  public onEndDateChanged(newLocalDate: string) {
    this.endDateTime = updateUtcDateTimeWithLocalDate(this.endDateTime, newLocalDate)
  }

  public onEndTimeChanged(newLocalTime: string) {
    this.endDateTime = updateUtcDateTimeWithLocalTime(this.endDateTime, newLocalTime)
  }

  public get minDate() {
    return todayLocalDate()
  }

  public onTabChange(tab: number) {
    this.tab = tab
  }

  public get canCreate() {
    return (!!this.endpoint || !!this.qualifiedEndpointPath ) && !!this.startDateTime && !!this.endDateTime && !!this.interval
  }

  public async onConfirm() {
    if (this.configuration) {
      this.showSaveConfirmationDialog = true
    } else {
      await this.onCreate()
    }
  }

  public async onCreate() {
    const options = {
      intervalInMilliseconds: this.interval * oneThousand,
      startDateTimeUtc: this.startDateTime,
      endDateTimeUtc: this.endDateTime,
    } as EndpointRecordingConfigurationOptions
    switch (this.tab) {
      case 0: { // device
        return await this.createDeviceEndpointRecordingConfiguration({
          endpointPath: this.endpoint + this.endpointPostfix,
          options,
        })
      }
      case 1: { // control
        return await this.createControlEndpointsRecordingConfiguration({
          controlId: this.selectedControlId,
          options,
        })
      }
      case 2: { // endpoint
        return await this.createPlcEndpointRecordingConfiguration({
          qualifiedEndpointPath: this.qualifiedEndpointPath,
          options,
        })
      }
    }
    this.onClose()
  }

  public async onSave() {
    await this.deleteEndpointRecordingConfiguration(this.configuration.id)
    await this.onCreate()
    this.showSaveConfirmationDialog = false
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
.date-time-select {
  display: flex;
  margin: -10px 10px 10px 10px;
  .date-select, .time-select {
    flex: 0;
    min-width: 150px;
    max-width: 150px;
  }
  .date-select {
    margin-right: 20px;
  }
}
</style>