import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { AlarmLevel, AlarmOperator } from '@ecocoach/domain-store-modules/src/common'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { AlarmDefinitionViewModel } from './models'

export function toViewModel(alarmDefinition: AlarmDefinitionModel, resolveStringResource: (resourceId: string) => string) {
  return {
    ...alarmDefinition,
    displayName: alarmDefinition.name || resolveStringResource(alarmDefinition.nameResourceId),
    displayMessage: alarmDefinition.message || resolveStringResource(alarmDefinition.messageResourceId),
    alarmConditionText: alarmConditionText(alarmDefinition, resolveStringResource),
    controlName: resolveStringResource(alarmDefinition.option.controlName),
    level: alarmDefinition.level ?? AlarmLevel.Critical,
  } as AlarmDefinitionViewModel
}

export function valueOptionsForAlarmDefinitionOption(isNumeric: boolean, resolveStringResource: (resourceId: string) => string)
: DropdownOption[] {
  return isNumeric ? [] :
    [
      { id: true, label: resolveStringResource('alarmdefinition.on') },
      { id: false, label: resolveStringResource('alarmdefinition.off') },
    ]
}

export function operatorOptionsForAlarmDefinitionOption(isNumeric: boolean, resolveStringResource: (resourceId: string) => string): DropdownOption[] {
  return isNumeric ? [
    { id: AlarmOperator.Equal, label: resolveStringResource('operator.equals') },
    { id: AlarmOperator.LessThan, label: resolveStringResource('operator.lessthan') },
    { id: AlarmOperator.GreaterThan, label: resolveStringResource('operator.greaterthan') },
  ] : []
}

function alarmConditionText(alarmDefinition: AlarmDefinitionModel, resolveStringResource: (resourceId: string) => string) {
  return alarmDefinition.option.isNumeric
    ? `${operatorOptionText(alarmDefinition.operator, resolveStringResource)} ${alarmDefinition.value} ${alarmDefinition.option.unit}`
    : booleanValueText(alarmDefinition.value as boolean, resolveStringResource)
}

function booleanValueText(value: boolean, resolveStringResource: (resourceId: string) => string) {
  return value
    ? resolveStringResource('alarmdefinition.on')
    : resolveStringResource('alarmdefinition.off')
}

function operatorOptionText(operator: AlarmOperator, resolveStringResource: (resourceId: string) => string) {
  switch (operator) {
    case AlarmOperator.Equal: return resolveStringResource('operator.equals')
    case AlarmOperator.LessThan: return resolveStringResource('operator.lessthan')
    case AlarmOperator.GreaterThan: return resolveStringResource('operator.greaterthan')
    default: return ''
  }
}
