<template>
  <v-layout column wrap>
    <v-flex xs12>
      <wizard-step-header 
        :title="dictionary('wizard.programm.load')" 
        :sub-title="plcName" 
      />
    </v-flex>
    <v-flex xs12>
      <v-stepper
        style="{background:transparent}"
        alt-labels
        :value="currentStep"
      >
        <v-stepper-header>
          <v-stepper-step :step="1" :complete="currentStep.step >= Step.CreateRelease">
            {{ 'release.create' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="2" :complete="currentStep.step >= Step.PreparingBuild">
            {{ 'release.stepper.queued' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="2" :complete="currentStep.step >= Step.Building">
            {{ 'release.stepper.building' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="3" :complete="currentStep.step >= Step.DeployRelease">
            {{ 'release.stepper.ready.for.deploy' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="4" :complete="currentStep.step >= Step.PreparingDeployment">
            {{ 'release.stepper.deploymentqueued' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="5" :complete="currentStep.step >= Step.Deploying">
            {{ 'release.stepper.deploying' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="6" :complete="currentStep.step >= Step.Verifying">
            {{ 'release.stepper.verifying' | translate }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :step="7" :complete="currentStep.step >= Step.ReleaseActive">
            {{ 'release.stepper.active' | translate }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-flex>
    <v-flex xs12 style="padding-bottom: 75px;">
      <div :class="[
        'outer', 
        hasError ? 'error' : '', 
        isWorking ? 'animated' : '', 
        isFinished ? 'finished' : '' ]">
        <div class="inner">
          <div class="content">
            <h1> {{ stepText | translate  }}</h1>
            <base-progress-bar v-if="hasDeploymentProgress" :value="currentStep.release.deploymentProgress" />
            <description-popover
              v-if="isEcocoachEmployee"
              class="infopopover"
              header="Debug info"
              :content="debugText"
            />

          </div>
          <div class="action">
            <div v-if="canCompleteRelease" class="group">
              <v-btn @click="askForReleasePropertiesInput" color="primary" :loading="isInteracted">
                {{ 'release.create' | translate }}
              </v-btn>
            </div>
            <div v-if="canDeployRelease" class="group">
              <v-btn @click="onDeployRelease" color="primary" :loading="isInteracted">
                {{ 'release.deploy' | translate }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-flex>
    <release-validation-issues-dialog/>
    <set-release-properties-dialog
      :show="showReleasePropertiesDialog"
      :disabled="isInteracted"
      :show-is-template-option="false"
      :show-update-template-operation-data-option="false"
      :show-archive-option="isEcocoachEmployee"
      :release-id="currentStep.release.id"
      :initial-name="currentStep.release.name"
      :initial-description="currentStep.release.description"
      @confirmed="onSetReleasePropertiesConfirmed($event)"
      @cancelled="onSetReleasePropertiesCancelled"
    />
    <confirm-dialog
      :show="showConfirmStartUpgradeDialog"
      :header="'release.upgraderequired' | translate"
      :text="confirmStartUpgradeDialogText"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onStartUpgrade"
      @cancelled="showConfirmStartUpgradeDialog = false"
    />
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeploymentState } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { ReleaseProgressStep, ReleaseProperties, ReleaseStepInfo, UpgradeRequiredReason } from '../../../../../store/modules/loadProgramUi/models'

import ReleaseValidationIssuesDialog from './ReleaseValidationIssuesDialog.vue'

import SetReleasePropertiesDialog from '../shared/SetReleasePropertiesDialog.vue'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const LoadProgramUi = namespace('loadProgramUi')
const ReleaseUi = namespace('releaseUi')
const App = namespace('app')
const Resource = namespace('resource')

@Component({
  components: {
    ReleaseValidationIssuesDialog,
    SetReleasePropertiesDialog,
  },
})
export default class LoadProgram extends Vue {
  @App.Getter public selectedPlc: PlcModel
  @App.Getter public isEcocoachEmployee: boolean
  @Resource.Getter public dictionary
  @LoadProgramUi.Getter public isInteracted: boolean
  @LoadProgramUi.Getter public upgradeRequiredReason: UpgradeRequiredReason
  @LoadProgramUi.Getter public currentStep: ReleaseStepInfo
  @LoadProgramUi.Action public setProperties: (input: ReleaseProperties) => Promise<void>
  @LoadProgramUi.Action public completeRelease: (payload: { archive: boolean }) => Promise<void>
  @LoadProgramUi.Action public deployRelease: (payload: { forceDeployment: boolean }) => Promise<void>
  @ReleaseUi.Mutation public showReleaseUpgradeConfirmationDialog: () => void

  public Step = ReleaseProgressStep
  public showReleasePropertiesDialog = false
  public showConfirmStartUpgradeDialog = false

  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }

  public get hasError() {
    return [ReleaseProgressStep.BuildError, ReleaseProgressStep.DeployError].includes(this.currentStep.step)
  }

  public get hasDeploymentProgress() {
    return this.currentStep.release.deploymentState === DeploymentState.VERIFYING_NEW_RELEASE
  }

  public get isWorking() {
    return [
      ReleaseProgressStep.PreparingBuild,
      ReleaseProgressStep.Building, 
      ReleaseProgressStep.PreparingDeployment,
      ReleaseProgressStep.Deploying,
      ReleaseProgressStep.Verifying,
    ]
      .includes(this.currentStep.step)
  }

  public get canCompleteRelease() {
    return [ReleaseProgressStep.CreateRelease, ReleaseProgressStep.BuildError].includes(this.currentStep.step)
  }

  public get canDeployRelease() {
    return [ReleaseProgressStep.DeployRelease, ReleaseProgressStep.RedeployRelease, ReleaseProgressStep.DeployError].includes(this.currentStep.step)
  }

  public get isFinished() {
    return this.currentStep.step === ReleaseProgressStep.ReleaseActive
  }

  public get stepText() {
    switch (this.currentStep.step) {
      case ReleaseProgressStep.CreateRelease: return 'release.rebuild.required'
      case ReleaseProgressStep.PreparingBuild: return 'release.queued'
      case ReleaseProgressStep.Building: return 'release.building'
      case ReleaseProgressStep.BuildError: return 'error.releasebuild'
      case ReleaseProgressStep.DeployRelease: return 'release.ready.for.deploy'
      case ReleaseProgressStep.RedeployRelease: return 'release.ready.for.redeploy'
      case ReleaseProgressStep.PreparingDeployment: return 'release.deploymentqueued'
      case ReleaseProgressStep.Deploying: return 'release.deploying'
      case ReleaseProgressStep.Verifying: return 'release.verifying'
      case ReleaseProgressStep.DeployError: return 'error.releasedeploy'
      case ReleaseProgressStep.ReleaseActive: return 'release.active'
      default: return ''
    }
  }

  public get debugText() {
    return [
      `Build state: ${this.currentStep.release.buildState}`,
      `Build state info: ${this.currentStep.release.buildStateInfo}`,
      `Deployment state: ${this.currentStep.release.deploymentState}`,
      `Deployment state info: ${this.currentStep.release.deploymentStateInfo}`,
    ].join('<br>')
  }

  public askForReleasePropertiesInput() {
    this.showReleasePropertiesDialog = true
  }

  public async onSetReleasePropertiesConfirmed(releaseProperties: ReleaseProperties) {
    await this.setProperties(releaseProperties)
    this.showReleasePropertiesDialog = false
    await this.completeRelease({ archive: releaseProperties.archive })
    if (this.upgradeRequiredReason !== UpgradeRequiredReason.None) {
      this.showConfirmStartUpgradeDialog = true
    }
  }

  public get confirmStartUpgradeDialogText() {
    switch (this.upgradeRequiredReason) {
      case UpgradeRequiredReason.Obsolete: return this.dictionary('release.upgraderequired.confirm')
      case UpgradeRequiredReason.Conflict: return this.dictionary('release.upgraderequired.confirmconflict')
      default: return this.dictionary('common.dialog.confirm')
    }
  }

  public onStartUpgrade() {
    this.showReleasePropertiesDialog = false
    this.showReleaseUpgradeConfirmationDialog()
  }

  public onSetReleasePropertiesCancelled() {
    this.showReleasePropertiesDialog = false
  }

  public onDeployRelease() {
    this.deployRelease({
      forceDeployment: false,
    })
  }
}
</script>

<style lang="scss" scoped>
.outer {
  height: 400px;
  width: 600px;
  margin: 0 auto;
  display: table;
  background: repeating-linear-gradient(45deg, transparent,  transparent 10px,#0584B9 10px, #0584B9 20px);
  background-size: 57px 57px;
  &.animated {
    -webkit-animation: ShiftBorder 1s linear infinite;
    -moz-animation: ShiftBorder 1s linear infinite;
    animation: ShiftBorder 1s linear infinite;
  }
  &.error {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, red 10px, red 20px);
  }
  &.finished {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, green 10px, green 20px);
  }
  .inner {
    background-color: #162E47;
    height: 320px;
    width: 520px;
    margin-top: 40px;
    margin-left: 40px;
    .content {
      text-align: center;
      align-items: center;
      color: white;
      text-transform: uppercase;
      padding: 30px;
      flex-direction: column;
    }
    .action {
      bottom: 0;
      text-align: center;
      margin-top: 50px;
        > .group {
        display: inline-block;
        margin: 0 auto;
      }
    } 
  }
}
@-webkit-keyframes ShiftBorder {
    from { background-position: 0 0; }
    to   { background-position: 60px 30px; }
}
@-moz-keyframes ShiftBorder {
    from { background-position: 0 0; }
    to   { background-position: 60px 30px; }
}
@keyframes ShiftBorder { 
    from { background-position: 0 0; }
    to   { background-position: 60px 30px; }
}

.theme--dark .v-stepper {
  background: transparent;
}
.v-stepper, .v-stepper__header {
    -webkit-box-shadow: none;
    box-shadow: none;
}

</style>
