import { RootState } from '@/store/types'
import { AlarmDefinitionAuthorizationsModel, DeviceAuthorizationsModel, Permission, PlcAuthorizationsModel, ProjectModel, RoomModel } from '@ecocoach/domain-store-modules/src/authorization/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { sortedByName, sortedBySortOrder, toMap } from '@ecocoach/domain-store-modules/src/utils'
import { GetterTree } from 'vuex'
import { hasAlarmDefinitionPermission, hasConsumptionPermission, hasDevicePermission, hasPlcPermission, hasProjectPermission, usersWithAlarmDefinitionPermissions, usersWithDevicePermissions, usersWithOneClickPermissions as usersWithConsumptionPermissions, usersWithPlcPermissions, usersWithProjectPermissions } from './helpers'
import { PermissionsViewModel, UserViewModel } from './models'
import { AuthorizationUiGetter, AuthorizationUiState } from './types'
import { AuthorizationGetter } from '@ecocoach/domain-store-modules/src/authorization/types'

const toThreeState = (totalNumber: number, otherNumber: number): boolean | null => {
  if (totalNumber > 0) {
    if (totalNumber === otherNumber) {
      return true
    }
    if (otherNumber === 0) {
      return false
    }
  }
  return null
}

export const getters: GetterTree<AuthorizationUiState, RootState> = {
  [AuthorizationUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [AuthorizationUiGetter.selectedProjectId] ({ selectedProjectId }): string {
    return selectedProjectId
  },
  [AuthorizationUiGetter.selectedUser] ({ selectedUser }): string {
    return selectedUser
  },
  [AuthorizationUiGetter.projects] (_, __, rootState): ProjectModel[] {
    return sortedByName(Array.from(toMap(rootState.authorization.projects.concat(rootState.authorization.consumptionProjects), p => p.id).values()))
  },
  [AuthorizationUiGetter.plcsOfProject] (_, __, rootState) {
    return (projectId: string): PlcAuthorizationsModel[] => 
      sortedByName(rootState.authorization.plcAuthorizations.filter(p => p.projectId === projectId))
  },
  [AuthorizationUiGetter.roomsOfPlc] (_, __, rootState) {
    return (plcId: string): RoomModel[] =>
      sortedBySortOrder(rootState.authorization.rooms.filter(r => r.plcId === plcId))
  },
  [AuthorizationUiGetter.devicesOfRoom] (_, __, rootState) {
    return (roomId: string): DeviceAuthorizationsModel[] =>
      sortedBySortOrder(rootState.authorization.deviceAuthorizations.filter(d => d.roomId === roomId))
  },
  [AuthorizationUiGetter.alarmDefinitionsOfPlc] (_, __, rootState) {
    return (plcId: string): AlarmDefinitionAuthorizationsModel[] => rootState.authorization.alarmDefinitionAuthorizations
      .filter(r => r.plcId === plcId)
  },
  [AuthorizationUiGetter.authorizedUsers] ({ newUsers }, __, rootState, rootGetters) {
    const toViewModel = (userIdentifier: string): UserViewModel => {
      return {
        userIdentifier,
        label: rootGetters[`authorization/${AuthorizationGetter.appRegistrationNameForId}`](userIdentifier) ?? userIdentifier,
      }
    }

    return (projectId: string): UserViewModel[] => {
      const plcIds = rootState.authorization.plcAuthorizations.filter(p => p.projectId === projectId).map(p => p.id)
      const deviceIds = rootState.authorization.deviceAuthorizations.filter(d => d.projectId === projectId).map(d => d.id)
      const alarmDefinitionIds = rootState.authorization.alarmDefinitionAuthorizations.map(d => d.id)
      const projectUsers = usersWithProjectPermissions([rootState.authorization.projectAuthorizations], projectId)
      const consumptionUsers = usersWithConsumptionPermissions([rootState.authorization.consumptionAuthorizations], projectId)
      const plcUsers = usersWithPlcPermissions(rootState.authorization.plcAuthorizations, plcIds)
      const deviceUsers = usersWithDevicePermissions(rootState.authorization.deviceAuthorizations, deviceIds)
      const alarmUsers = usersWithAlarmDefinitionPermissions(rootState.authorization.alarmDefinitionAuthorizations, alarmDefinitionIds)
      const existingUsers = Array.from(new Set([...projectUsers, ...consumptionUsers, ...plcUsers, ...deviceUsers, ...alarmUsers].filter(u => !newUsers.includes(u))))
        .map(toViewModel)
        .sort((a, b) => a.label.localeCompare(b.label))
      const addingUsers = newUsers.map(toViewModel)
      return existingUsers.concat(addingUsers) // leave new users at bottom of list
    }
  },
  [AuthorizationUiGetter.projectPermissions] (state, __, rootState, rootGetters) {
    return (projectId: string): PermissionsViewModel | null => {
      const project = rootState.authorization.projectAuthorizations.id === projectId 
        ? rootState.authorization.projects.find(p => p.id === projectId)
        : null
      return project ? {
        id: project.id,
        name: project.name,
        icon: '',
        hasRead: hasProjectPermission(rootGetters, project.id, state.selectedUser, Permission.READ),
        hasWrite: hasProjectPermission(rootGetters, project.id, state.selectedUser, Permission.EDIT),
        hasAuthorize: hasProjectPermission(rootGetters, project.id, state.selectedUser, Permission.AUTHORIZE),
      } as PermissionsViewModel : null
    }
  },
  [AuthorizationUiGetter.consumptionPermissions] (state, __, rootState, rootGetters) {
    return (projectId: string): PermissionsViewModel | null => {
      const project = rootState.authorization.consumptionAuthorizations.id === projectId
        ? rootState.authorization.consumptionProjects.find(p => p.id === projectId)
        : null
      return project ? {
        id: project.id,
        name: project.name,
        icon: '',
        hasRead: hasConsumptionPermission(rootGetters, project.id, state.selectedUser, Permission.READ),
        hasWrite: hasConsumptionPermission(rootGetters, project.id, state.selectedUser, Permission.EDIT),
        hasAuthorize: hasConsumptionPermission(rootGetters, project.id, state.selectedUser, Permission.AUTHORIZE),
      } as PermissionsViewModel : null
    }
  },
  [AuthorizationUiGetter.plcPermissions] (state, __, rootState, rootGetters) {
    return (plcId: string): PermissionsViewModel => {
      const plc = rootState.authorization.plcAuthorizations.find(p => p.id === plcId)!
      return {
        id: plc.id,
        name: plc.name,
        icon: 'eco.home.white',
        hasRead: hasPlcPermission(rootGetters, plc.id, state.selectedUser, Permission.READ),
        hasExecute: hasPlcPermission(rootGetters, plc.id, state.selectedUser, Permission.EXECUTE),
        hasWrite: hasPlcPermission(rootGetters, plc.id, state.selectedUser, Permission.EDIT),
        hasAuthorize: hasPlcPermission(rootGetters, plc.id, state.selectedUser, Permission.AUTHORIZE),
        hasSystemNotification: hasPlcPermission(rootGetters, plcId, state.selectedUser, Permission.SYSTEM_NOTIFICATION),
        hasSystemNotificationEmail: hasPlcPermission(rootGetters, plcId, state.selectedUser, Permission.SYSTEM_NOTIFICATION_EMAIL),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.devicePermissions] (state, __, rootState, rootGetters) {
    return (deviceId: string): PermissionsViewModel => {
      const device = rootState.authorization.deviceAuthorizations.find(d => d.id === deviceId)!
      return {
        id: device.id,
        name: device.name,
        icon: device.icon,
        hasRead: hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.READ),
        hasExecute: hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.EXECUTE),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.devicePermissionsForRoom] (state, __, rootState, rootGetters) {
    return (roomId: string): PermissionsViewModel => {
      const room = rootState.authorization.rooms.find(r => r.roomId === roomId)!
      const devices = rootState.authorization.deviceAuthorizations.filter(d => d.roomId === roomId)
      const numDevicesTotal = devices.length
      const numDevicesWithRead = devices.filter(device =>
        hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.READ)).length
      const numDevicesWithExecute = devices.filter(device =>
        hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.EXECUTE)).length
      return {
        id: room.roomId,
        name: room.name,
        icon: room.icon,
        hasRead: toThreeState(numDevicesTotal, numDevicesWithRead),
        hasExecute: toThreeState(numDevicesTotal, numDevicesWithExecute),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.devicePermissionsForPlc] (state, __, rootState, rootGetters) {
    return (plcId: string): PermissionsViewModel => {
      const plc = rootState.authorization.plcAuthorizations.find(p => p.id === plcId)!
      const devices = rootState.authorization.deviceAuthorizations.filter(d => d.plcId === plcId)
      const numDevicesTotal = devices.length
      const numDevicesWithRead = devices.filter(device =>
        hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.READ)).length
      const numDevicesWithExecute = devices.filter(device =>
        hasDevicePermission(rootGetters, device.id, state.selectedUser, Permission.EXECUTE)).length
      return {
        id: plc.id,
        name: plc.name,
        icon: 'eco.home.white',
        hasRead: toThreeState(numDevicesTotal, numDevicesWithRead),
        hasExecute: toThreeState(numDevicesTotal, numDevicesWithExecute),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.alarmDefinitionPermissions] (state, __, rootState, rootGetters) {
    return (alarmDefinitionId: string): PermissionsViewModel => {
      const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
      const alarmDefinition = rootState.authorization.alarmDefinitionAuthorizations.find(a => a.id === alarmDefinitionId)!
      return {
        id: alarmDefinition.id,
        name: alarmDefinition.name || resolveStringResource(alarmDefinition.nameResourceId),
        deviceName: alarmDefinition.deviceName,
        icon: 'eco.energy.white',
        hasCustomNotification: 
          hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, state.selectedUser, Permission.CUSTOM_NOTIFICATION),
        hasCustomNotificationEmail: 
          hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, state.selectedUser, Permission.CUSTOM_NOTIFICATION_EMAIL),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.alarmDefinitionPermissionsForPlc] (state, __, rootState, rootGetters) {
    return (plcId: string): PermissionsViewModel => {
      const alarmDefinitions = rootState.authorization.alarmDefinitionAuthorizations.filter(a => a.plcId === plcId)
      const alarmDefinitionsTotal = alarmDefinitions.length
      const numAlarmDefinitionsWithCustomNotification = alarmDefinitions.filter(alarmDefinition =>
        hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, state.selectedUser, Permission.CUSTOM_NOTIFICATION)).length
      const numAlarmDefinitionsWithCustomNotificationEmail = alarmDefinitions.filter(alarmDefinition =>
        hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, state.selectedUser, Permission.CUSTOM_NOTIFICATION_EMAIL)).length
      return {
        id: plcId,
        name: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarmdefinition.all'),
        icon: 'eco.energy.white',
        hasCustomNotification: toThreeState(alarmDefinitionsTotal, numAlarmDefinitionsWithCustomNotification),
        hasCustomNotificationEmail: toThreeState(alarmDefinitionsTotal, numAlarmDefinitionsWithCustomNotificationEmail),
      } as PermissionsViewModel
    }
  },
  [AuthorizationUiGetter.appRegistrationNameForId] (_, __, ___, rootGetters) {
    return (appId: string): string | null => rootGetters[`authorization/${AuthorizationGetter.appRegistrationNameForId}`](appId)
  },
}
